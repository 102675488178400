.subtitle {
    font-family: "Nunito", sans-serif;
}

.contestsContainer {
    margin-bottom: 1.25rem;
}

.contestsContainer div {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
}

.contestsResultLink {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border-width: 1px 0;
    border-style: solid;
    text-decoration: underline;
}

.contestsResultLink img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}

.contestsResultLink p {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    line-height: 1.6875rem;
    font-weight: bold;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin: 1rem 0;
}

.pageButton {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
}

.pageButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pageButton.active {
    background-color: #007bff;
    color: #fff;
}

/* CSS Responsivo */
@media (max-width: 768px) {
    .contestsResultLink {
      font-size: 0.875rem; /* Tamanho de fonte menor em telas pequenas */
      padding: 1rem;
    }
  
    .pagination {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  
    .pageButton {
      padding: 0.25rem 0.5rem;
    }
  }
  
  @media (min-width: 769px) {
    .contestsResultLink {
      font-size: 1rem;
    }
  }
  