/* PrivacyPolicy.module.css */

.policyContainer {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .policyTitle {
    margin-top: 1rem;
  }
  
  .policyText {
    text-align: justify;
    line-height: 1.5;
    margin-top: 1.25rem;
  }
  
  .policyText p {
    margin-top: 0.5rem;
  }
  