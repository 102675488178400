/* LegalDisclaimer.module.css */

.legalContainer {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .legalText {
    text-align: justify;
    line-height: 1.5;
    margin-top: 1.25rem;
  }
  
  .legalText p {
    margin-top: 0.5rem;
  }
  
  .legalText ul {
    margin-left: 1.25rem;
    list-style-type: disc;
  }
  
  .legalLink {
    text-decoration: none;
  }
  
  .legalLink:hover {
    text-decoration: underline;
  }
  