  .header {
      background: var(--brand-blue);
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      padding: 1.25rem 1rem;
      position: relative;
      /* Adiciona posição relativa para o menu dropdown */
  }

  .logoContainer {
      margin-bottom: 0;
  }

  .headerLink {
      color: white;
      text-decoration: none;
      font-size: var(--font-size-large);
      font-weight: 700;
  }

  .header a:hover {
      color: white;
  }

  /* Estilo do ícone de menu hambúrguer */
  .menuToggle {
      display: none;
      flex-direction: column;
      gap: 4px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
  }

  .menuIcon {
      width: 30px;
      height: 3px;
      background-color: white;
  }

  /* Estilos do menu de navegação */
  .nav {
      width: 100%;
      display: flex;
      justify-content: center;
  }

  .navList {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      justify-content: center;
      gap: 1rem;
  }

  .navLink {
      color: white;
      text-decoration: none;
      font-size: 1.125rem;
      font-weight: 500;
  }

  .navLink:hover {
      text-decoration: underline;
  }

  /* Responsividade para tablets e dispositivos móveis */
  @media (max-width: 768px) {
      .navList {
          flex-direction: column;
          align-items: center;
          gap: 0.75rem;
          display: none;
          /* Inicialmente oculto */
          background-color: var(--brand-blue);
          /* Cor de fundo do menu */
          width: 100%;
          padding: 1rem 0;
          position: absolute;
          top: 60px;
          /* Distância entre o título e o menu */
          left: 0;
          z-index: 10;
          /* Garante que o menu fique sobre outros conteúdos */
      }

      .nav.open .navList {
          display: flex;
          /* Exibe o menu quando "open" é ativo */
      }

      .headerLink {
          display: flex;
          font-size: 1.5rem;
          width: 220px;
      }

      .menuToggle {
          display: flex;
          /* Exibe o botão de menu hambúrguer */
          margin-right: 1rem;
          /* Alinha o menu à direita */
      }
  }

  /* Responsividade para desktops */
  @media (min-width: 1024px) {
      .navList {
          gap: 1.5rem;
      }

      .headerLink {
          font-size: 2rem;
      }

      .menuToggle {
          display: none;
          /* Oculta o menu hambúrguer em telas grandes */
      }
  }