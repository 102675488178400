/* AboutPage.module.css */

.aboutContainer {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    min-height: 65vh;
  }
  
  .aboutSection {
    margin-bottom: 2rem;
  }
  
  .aboutTitle {
    margin: 1rem 0;
  }
  
  .aboutText {
    text-align: justify;
    line-height: 1.5;
  }
  