.transparenciaContainer {
    padding: 20px;
}

.transparenciaTitle {
    font-size: 2em;
    margin-bottom: 20px;
    margin-top: 1rem;
}

.transparenciaText {
    font-size: 1em;
    line-height: 1.6;
}

.transparenciaLink {
    color: var(--darker-blue);
    text-decoration: none;
}

.transparenciaLink:hover {
    text-decoration: underline;
}