/* ContactPage.module.css */

.contactContainer {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    min-height: 65vh;
  }
  
  .contactForm {
    display: flex;
    flex-direction: column;
  }
  
  .contactInput, .contactTextArea {
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .contactButton {
    padding: 10px 20px;
    background-color: var(--brand-blue);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .contactTitle {
    margin-top: 1rem;
  }
  