.privacyControl {
    position: fixed;
    bottom: 5px;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 10px 20px;
    z-index: 2;
    left: 0;
  }
  
  .privacyBanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0;
  }

  .privacyBanner a {
    color: white;
    font-size: 1rem;
  }
  
  .privacyBanner p {
    margin: 0;
    padding: 0;
    flex: 1;
    font-size: 1rem;
  }
  
  .btnAccept,
  .btnReject {
    background-color: var(--brand-blue);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    margin: 8px 8px 0;
    font-size: 1rem;
    border-radius: 10px;
    text-align: center;
  }
  
  .btnAccept {
    background-color: var(--dark-green);
  }
  
  .btnAccept:hover,
  .btnReject:hover {
    opacity: 0.8;
  }
  
  @media (min-width: 600px) {
    .privacyBanner {
      flex-direction: row;
      justify-content: space-between;
      margin: auto;
    }
  
    .btnAccept,
    .btnReject {
      margin-left: 10px;
      margin-right: 0;
      padding: 10px 15px;
    }
  }
  
  @media (min-width: 1024px) {
    .privacyControl {
      padding: 15px 30px;
    }
  
    .privacyBanner {
      max-width: 600px;
    }
  
    .btnAccept,
    .btnReject {
      padding: 12px 20px;
    }
  }
  