.productAdBanner {
    display: grid;
    gap: 20px;
    padding: 20px;
  }

  .productAdBanner p{
    margin: 0.5rem 0;
    font-size: 1rem;
    
  }

  .productAdBanner a{
    text-decoration: none;
    display: flex;
    flex-direction: column;
  }
  
  .productAd {
    position: relative; /* Necessário para o posicionamento absoluto dos elementos internos */
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .productAd:hover {
    transform: translateY(-10px);
  }
  
  .productImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: relative; /* Necessário para o posicionamento das estrelas e nota */
  }
  
  /* Nota de avaliação com estrelas */
  .rating {
    width: 100%;
    padding: 2px 10px;
    text-align: center;
    font-size: 0.5rem;
    font-weight: bold;
    color: var(--dark-gray);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 1;
    display: flex;
    border-radius: 10px;
  }
  
  /* Estrelas amarelas */
  .stars {
    font-size: 1.2rem;
    color: var(--dark-orange); /* Cor das estrelas */
    margin-right: 5px;
  }
  
  /* Desconto */
  .discount {
    font-weight: bold;
    color: white;
    font-size: 0.95rem;
    padding: 0.2rem 0.75rem;
    background-color: var(--dark-burgundy);
    width: fit-content;
    height: fit-content;
    text-align: center;
    border-radius: 50px;
    margin: 0;
  } 

  .oldPrice{
    text-decoration: line-through;
    color: #555 !important;
  }

  .newPrice{
    font-weight: bold;
    font-size: 1.2rem !important;
    color: var(--dark-green) !important;
  }
  
  .coupons h2{
    font-size: 1rem;
  }
  
  .coupons h2,
  .additionalInfo h2,
  .ratings p {
    font-weight: bold;
    color: var(--dark-gray);
  }
  
  .coupons ul,
  .additionalInfo ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .coupons li,
  .additionalInfo li {
    font-size: 0.8rem;
    color: white;
    font-weight: bold;
    background-color: var(--dark-green);
    border-radius: 10px;
    padding: 2px 10px;
    width: max-content;
    margin: 0.3rem;
  }
  
  .ratings {
    margin-top: 10px;
  }
  
  .rating {
    font-size: 1rem;
    color: var(--dark-orange);
  }
  
  .reviews,
  .sold {
    font-size: 0.9rem;
    color: #777;
  }
  