.breadcrumb {
display: flex;
list-style: none;
padding: 0;
margin: 16px 0 0;
}

.breadcrumbItem {
margin-right: 0.5rem;
}

.breadcrumbItem + .breadcrumbItem::before {
content: '/';
padding: 0.5rem;
}