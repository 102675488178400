.contestPageTitle {
    margin: 1rem 0;
    /* Margem superior e inferior de 1rem */
}

.loterryPageContainer h2{
    margin-top: 1rem;
}

.drawnNumbersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.drawnNumbersWrapper {
    display: grid;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 1rem;
    max-width: 300px;
}

.drawnNumbers {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0efef;
    /* Cor de fundo durante o carregamento */
    font-size: 20px;
    /* Ajuste o tamanho da fonte se necessário */
}


.containerDate {
    display: flex;
}

.containerDate p {
    padding: 0 16px 0 0;
}

.contestTable {
    line-height: 2.063rem;
    margin-bottom: 1.25rem;
    overflow-x: auto;
    margin-top: 1rem;
}

.copyButton {
    background-color: var(--brand-blue);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.copyButton:hover {
    background-color: var(--darker-blue);
}

.weekDraw p {
    font-weight: 600;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shareButton {
    background-color: var(--dark-green);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    margin-left: 20px;
}

.shareButton:hover {
    opacity: 0.8;
}