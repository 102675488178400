/* TermsOfUse.module.css */

.termsContainer {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .termsTitle {
    margin-top: 1rem;
  }
  
  .termsText {
    text-align: justify;
    line-height: 1.5;
    margin-top: 1.25rem;
  }
  
  .termsText p {
    margin-top: 0.5rem;
  }
  