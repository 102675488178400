:root {
  --brand-blue: #0072C6;     

  --lotofacil-color: #930989;   
  --megasena-color: #008740;   
  --quina-color: #2e3192;   
  --lotomania-color: #af5802;   
  --duplasena-color: #a62b43;   
  --diadesorte-color: #7e6906;   
  --supersete-color: #038141;   
  --loteca-color: #d4181f;
  --timemania-color: #038141;
  --maismilionaria-color: #004f8b;

  --dark-burgundy: #800000;      
  --dark-green: #027202;         
  --darker-blue: #005A9C;        
  --dark-gray: #333333;          
  --dark-orange: #c53d22;
  --dark-gray-almost-black: #1a1a1a;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  color: var(--dark-gray);
  line-height: 1.6;               /* Melhorando o line-height global para textos */
  font-size: 1rem;                /* Definindo uma base de 1rem (16px) */
}

h1, h2, h3 {
  margin: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

p,
strong,
li,
a {
  font-size: 1.125rem;            /* Ajustando um pouco a fonte para os parágrafos e links */
  line-height: 1.75rem;           /* Proporção de line-height ajustada */
}

h1 {
  font-size: 2rem;                /* Tamanho de fonte para título principal */
  line-height: 2.5rem;            /* Tamanho do line-height proporcional ao novo font-size */
}

h2 {
  font-size: 1.5rem;              /* Tamanho de fonte para h2 */
  line-height: 2rem;              /* Ajustando o line-height para h2 */
}

h3 {
  font-size: 1.25rem;             /* Reduzindo um pouco o tamanho do h3 */
  line-height: 1.875rem;          /* Ajustando o line-height proporcionalmente */
}

.lotofacilDrawnNumbers, .quinaDrawnNumbers, .lotomaniaDrawnNumbers {
  grid-template-columns: repeat(5, 50px);
}

.megasenaDrawnNumbers, .duplasenaDrawnNumbers, .superseteDrawnNumbers, .diadesorteDrawnNumbers {
  grid-template-columns: repeat(3, 50px);
}

.quinaDrawnNumbers {
  grid-template-columns: repeat(5, 50px);
}
