.footerContainer {
    background-color: var(--brand-blue);
    padding: 20px 0;
    text-align: center;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerContainer a:hover{
    color: white;
}

.footerLink {
    color: white;
    margin: 0 10px;
    padding: 0.75rem;
    min-width: 100px;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footerText {
    font-size: 0.875rem;
    margin-top: 20px;
    color: white;
    max-width: 800px;
}